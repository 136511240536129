import React, { useEffect } from 'react';
import sal from 'sal.js';
import 'sal.js/dist/sal.css';

const DEFAULT_OPTIONS = {
  threshold: 0.3,
  once: true,
};

/**
 * Sal.js
 * Performance focused, lightweight (less than 2.8 kb) scroll animation library, written in vanilla JavaScript.
 * https://github.com/mciastek/sal
 */
const Scroll = ({
  children,
  options,
  ...props
}) => {

  useEffect(() => {
    sal({
      ...DEFAULT_OPTIONS,
      ...options,
    });
  }, []);

  return (
    <div
      data-sal="fade"
      data-sal-duration="2000"
      data-sal-delay="300"
      data-sal-easing="ease-out-back"
      {...props}
    >
      {children}
    </div>
  );
};

export { Scroll };