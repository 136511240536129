import { useState, useEffect, useCallback } from 'react';

const useMatchBreakpoint = (width) => {
  const [match, setMatch] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= width : 0,
  );

  const calcInnerWidth = useCallback(() => {
    setMatch(window.innerWidth <= width);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return match;
};

export default useMatchBreakpoint;
