import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Collapse from '@kunukn/react-collapse';
import { Parallax } from 'react-parallax';
import { getWikiDescFromNode } from '../common/utils';
import Layout from '../components/Layout';
import LinkButton from '../components/LinkButton';
import LifeCalculator from '../components/LifeCalculator2';
import MonthlyCostGraph from '../components/MonthlyCostGraph';
import SEO from '../components/seo';
import { GET_STARTED_LIFE_MS_ROUTE, LIFE_MS_ROUTE } from '../constants/routes';
import BlackQ from '../images/life/q-black.svg';
import useContentfulWiki from '../hooks/useContentfulWiki';
import styles from './styles/life-insurance.module.scss';
import AccordionList from '../components/AccordionList';
import CurveDownSvg from '../images/layout/curve-down.svg';
import CurveUpSvg from '../images/layout/curve-up.svg';
import { Scroll } from '../components/Animation';

const LIFE_GUIDE_LIST = [
  <p key="timeline1">
    Pick your <br />
    cover amount
  </p>,
  <p key="timeline2">
    Compare your <br />
    quotes online
  </p>,
  <p key="timeline3">
    Talk to an expert <br />
    if you need
  </p>,
  <p key="timeline4">
    Select & sign up for <br />
    the cover you want
  </p>,
  <p key="timeline5">
    Enjoy a <br />
    worry-free life
  </p>,
];

const Header = (
  <div className={styles.cta}>
    <div className="container">
      <h1>
        Making life insurance <br />
        easy for Kiwis
      </h1>
      <p>
        We have partnered up with various New Zealand life insurance providers
        to bring you affordable and rewarding insurance.
      </p>
      <LinkButton
        href={GET_STARTED_LIFE_MS_ROUTE}
        background="#4187F5"
        className={styles.blueBtn}
      >
        Get started
      </LinkButton>
    </div>
  </div>
);

const LifeInsurance = () => {
  const [expandFaqs, setExpandFaqs] = useState(null);

  const images = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "life/life-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        background2: file(relativePath: { eq: "life/life-bg2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hands: file(relativePath: { eq: "life/life-hands.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        brand0: file(relativePath: { eq: "brands/aia-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand1: file(relativePath: { eq: "brands/asteron-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand2: file(relativePath: { eq: "brands/partners-life-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand3: file(relativePath: { eq: "brands/cigna-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brand4: file(relativePath: { eq: "brands/fidelity-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  const wiki = useContentfulWiki();

  const lifeFaqs = useMemo(() => {
    const faq = wiki.find((w) => w.title === 'Life');
    return getWikiDescFromNode(faq);
  }, [wiki]);

  return (
    <Layout
      className={styles.container}
      mini={true}
      appUrl={LIFE_MS_ROUTE}
      signUpUrl={GET_STARTED_LIFE_MS_ROUTE}
    >
      <SEO title="Life Insurance" />
      <Parallax
        bgImage={images.background.childImageSharp.fluid.srcWebp}
        bgImageAlt="background"
        strength={400}
        className={styles.parallax}
      >
        <div className={styles.header}>
          {Header}
          <div className={styles.quotes}>
            <div className="container">
              <Scroll className={styles.quoteWrap}>
                <div className={styles.quote}>
                  Did you know approximately 9,000 New Zealanders die from
                  cancer each year?
                </div>
              </Scroll>
              <Scroll className={styles.quoteWrap}>
                <div className={styles.quote}>
                  More than $300 million in life insurance claims paid out to
                  Kiwis in Q4 2021.
                </div>
              </Scroll>
              <Scroll className={styles.quoteWrap}>
                <div className={styles.quote}>
                  Kiwis have over 4 million life insurance covers with 92,000
                  bought in 2021 alone.
                </div>
                <div className={styles.quoteBtn}>
                  <LinkButton
                    href={GET_STARTED_LIFE_MS_ROUTE}
                    background="white"
                    className={styles.whiteBtn}
                  >
                    Get started
                  </LinkButton>
                </div>
              </Scroll>
            </div>
          </div>
        </div>
        <div className={styles.section1Bottom}>
          <div className={styles.topBlur}></div>
          <div className={styles.partnersContainer}>
            <b>Some of our partners</b>
            <div className={classNames(styles.partners)}>
              {Array(5)
                .fill()
                .map((_, i) => {
                  const key = `brand${i}`;
                  return (
                    <Img
                      key={key}
                      className={styles.partner}
                      fluid={images[key].childImageSharp.fluid}
                      alt={key}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  );
                })}
            </div>
          </div>
          <CurveUpSvg className={styles.curveUp} />
        </div>
      </Parallax>
      <div className={styles.section2}>
        <div className={styles.section2Content}>
          <div className={classNames('container', styles.sectionItemWrap)}>
            <div className={styles.sectionItem}>
              <h2>What is life insurance?</h2>
              <div className={styles.sectionDesc}>
                <p>
                  A type of insurance that pays out a sum of money (e.g.
                  $250,000) if the insured person dies or is diagnosed with a
                  terminal illness and unlikely to live beyond 12 months.
                </p>
              </div>
            </div>
            <Img
              className={styles.img}
              fluid={images.hands.childImageSharp.fluid}
            />
          </div>
          <div className={classNames('container', styles.sectionItemWrap)}>
            <div className={styles.fill}></div>
            <div className={styles.sectionItem}>
              <h2>Why do you need life insurance?</h2>
              <div className={styles.sectionDesc}>
                <p>
                  It is one way to provide money for your family and loved ones
                  if you pass away. It will pay for your funeral, provide for
                  living expenses and other things you want for your family.
                </p>
                <p>
                  For some, it can also be money you use to complete bucket list
                  items if you are diagnosed with terminal illness and have less
                  than 12 months to live. For example, if you are diagnosed with
                  terminal cancer and you want to travel the world or live in
                  luxury for your remaining days.
                </p>
              </div>
            </div>
          </div>
        </div>
        <BlackQ className={styles.bigBlackQ} />
      </div>
      <Parallax
        bgImage={images.background2.childImageSharp.fluid.srcWebp}
        bgImageAlt="section3"
        className={styles.section3}
      >
        <div className={classNames(styles.chartSection, styles.fullHeight)}>
          <div className={styles.title}>
            <h1>Average monthly cost of insurance in NZ</h1>
          </div>
          <MonthlyCostGraph />
        </div>
        <div className={classNames(styles.calcSection, styles.fullHeight)}>
          <div className={styles.title}>
            <h1>How much will life insurance cost you?</h1>
            <p>Get an estimate to see how much it will cost you</p>
          </div>
          <div className={styles.calculator}>
            <LifeCalculator />
          </div>
        </div>
        <CurveDownSvg className={styles.curveDown} />
      </Parallax>
      <div className={styles.section4}>
        <div className={styles.title}>
          <h1>Buying life insurance with Quashed is quick & easy</h1>
          <p>Here is a guide to buying life insurance with Quashed</p>
        </div>
        <div className={classNames(styles.timeline, 'container')}>
          {LIFE_GUIDE_LIST.map((text, idx) => (
            <div key={`${text}_${idx}`} className={styles.timelineItem}>
              <h1>{idx + 1}</h1>
              {text}
              <div className={styles.line}></div>
              <div className={styles.dot}></div>
            </div>
          ))}
        </div>
        <CurveUpSvg className={styles.curveUpWhite} />
      </div>
      <div className={styles.section5}>
        <div className={styles.title}>
          <h1>The smarter way to buy life insurance</h1>
          <p>
            We have helped thousands of Kiwis compare and purchase their
            insurance.
          </p>
        </div>
        <div className={styles.content}>
          <ul>
            <li>Explore life insurance in your own time</li>
            <li>
              Get life insurance quotes easily and online in a few minutes
            </li>
            <li>Get free advice from licensed and experienced advisers</li>
            <li>We are here to support you if you get stuck</li>
            <li>Access to the latest offers and rewards</li>
          </ul>
          <LinkButton
            href={GET_STARTED_LIFE_MS_ROUTE}
            background="#4187F5"
            className={styles.blueBtn}
          >
            Get quotes - it’s free
          </LinkButton>
        </div>
      </div>
      <div className={styles.section6}>
        <div className={classNames(styles.section6Wrap, 'container')}>
          <div className={styles.faqsHeader}>
            <div className={styles.title}>
              <h1>FAQs</h1>
            </div>
            <AccordionList
              titleClassName={styles.faqsTitle}
              items={lifeFaqs.slice(0, 6)}
            />
          </div>
          <Collapse
            isOpen={expandFaqs}
            className={classNames(
              'collapse-css-transition',
              styles.faqsContent,
              expandFaqs && styles.open,
              expandFaqs && 'open',
            )}
          >
            <AccordionList
              titleClassName={styles.faqsTitle}
              items={lifeFaqs.slice(7, lifeFaqs.length)}
            />
          </Collapse>
          <div
            className={classNames(
              styles.faqsArrow,
              expandFaqs && styles.rotate,
            )}
            onClick={() => setExpandFaqs(!expandFaqs)}
          >
            <i className="fa-solid fa-chevron-down" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LifeInsurance;
