import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { fetch } from 'whatwg-fetch';
import styles from './styles/LifeCalculator2.module.scss';
import Button from './Button';
import Spinner from './Spinner';
import LinkButton from './LinkButton';
import Select from 'react-select';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { debounce } from '../common/utils';
import { GET_STARTED_LIFE_MS_ROUTE } from '../constants/routes';
import { segmentTrackEvent } from '../common/utils';

const initialState = {
  age: '',
  amount: 250000,
  loading: false,
  estimate: '',
};

const options = [
  {
    value: 250000,
    label: '$250,000',
  },
  {
    value: 500000,
    label: '$500,000',
  },
  {
    value: 750000,
    label: '$750,000',
  },
  {
    value: 1000000,
    label: '$1,000,000',
  },
  {
    value: 1250000,
    label: '$1,250,000',
  },
  {
    value: 1500000,
    label: '$1,500,000',
  },
];

const isValidAge = (age) => {
  const ageInt = parseInt(age);
  return ageInt > 15 && ageInt < 71;
};

const isValid = (age, amount) => {
  if (age && amount) {
    return isValidAge(age);
  }
  return false;
};

const mergeState = (obj) => (prevState) => ({
  ...prevState,
  ...obj,
});

const getEstimate = async (baseUrl, data) => {
  // const url = `https://app.quashed.co.nz/external/quote`;
  const url = `${baseUrl}/external/quote`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

const LifeCalculator2 = ({ className }) => {
  const debounceRef = useRef();
  const siteMetadata = useSiteMetadata();
  const [state, setState] = useState(initialState);

  const updateEstimate = async (age, amount) => {
    setState(mergeState({ loading: true }));
    segmentTrackEvent('Marketing-Life-Calculator-Get-Estimate');
    const data = await getEstimate(siteMetadata.APP_URL, {
      age,
      insuredAmount: amount,
    });
    setState(
      mergeState({
        estimate: data.Monthly,
        loading: false,
      }),
    );
  };

  const handleClick = () => {
    segmentTrackEvent('Marketing-Life-Calculator-See-Prices-Click');
    if (state.age && state.amount) {
      updateEstimate(state.age, state.amount.value);
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    const value = e.target.value;
    setState(mergeState({ age: value }));

    if (state.estimate && isValidAge(value)) {
      debounceRef.current = debounce(
        () => {
          updateEstimate(value, state.amount.value);
        },
        500,
        debounceRef.current,
      );
    }
  };

  const handleSelectChange = (data) => {
    // Value is the option item: { value: string, label: number }
    setState(mergeState({ amount: data }));

    if (state.estimate) {
      updateEstimate(state.age, data.value);
    }
  };

  return (
    <div
      className={classnames(
        styles.container,
        state.estimate && styles.noMargin,
        className,
      )}
    >
      <div className={styles.fields}>
        <label>
          What’s your age?
          <input
            name="age"
            className={styles.input}
            value={state.age}
            type="number"
            onChange={handleInputChange}
            placeholder="Enter your age"
            min={16}
            max={70}
          />
        </label>
        <label>
          How much cover?
          <Select
            className={styles.select}
            classNamePrefix="select"
            options={options}
            placeholder="$250K - $1.5M"
            value={state.amount}
            isSearchable={false}
            onChange={handleSelectChange}
          />
        </label>
        {state.loading ? (
          <div className={styles.loading}>
            <Spinner inverted={true} className={styles.spinner} />
          </div>
        ) : (
          state.estimate && (
            <div className={styles.estimate}>
              <p>It will roughly cost you</p>
              <h2>{`$${state.estimate.low} - $${state.estimate.high}`}</h2>
              <p>per month</p>
            </div>
          )
        )}
      </div>
      {state.estimate ? (
        <LinkButton
          href={GET_STARTED_LIFE_MS_ROUTE}
          background="#4187F5"
          className={styles.getStarted}
          eventName="Marketing-Life-Calculator-Get-Started-Click"
        >
          Continue
        </LinkButton>
      ) : (
        isValid(state.age, state.amount && state.amount.value) && (
          <Button
            className={styles.button}
            text="Enter"
            onClick={handleClick}
          />
        )
      )}
    </div>
  );
};

export default LifeCalculator2;
