import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';
import Slider from 'react-slick';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import styles from './styles/MonthlyCostGraph.module.scss';
import useMatchBreakpoint from '../hooks/useMatchBreakpoint';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = ['250K coverage', '500K coverage', '1M coverage'];

const commonDataset = [
  {
    label: 'Female',
    backgroundColor: '#4187F5',
    categoryPercentage: 0.6,
    maxBarThickness: 15,
    clip: 30,
  },
  {
    label: 'Male',
    backgroundColor: '#3D67AB',
    categoryPercentage: 0.6,
    maxBarThickness: 15,
    clip: 30,
  },
];

const datasets = {
  [options[0]]: [
    [10, 12, 35, 90],
    [24, 20, 40, 100],
  ],
  [options[1]]: [
    [25, 28, 55, 150],
    [40, 34, 65, 190],
  ],
  [options[2]]: [
    [37, 45, 92, 270],
    [70, 57, 100, 305],
  ],
};

const defaultConfig = {
  type: 'bar',
  data: {
    labels: ['25 yrs', '35 yrs', '45 yrs', '55 yrs'],
  },
  options: {
    elements: {
      bar: {
        borderRadius: 5,
      },
    },
    indexAxis: 'y',
    layout: {
      padding: {
        right: 30,
      },
    },
    plugins: {
      legend: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        max: 300,
        ticks: {
          padding: 40,
          stepSize: 60,
          count: 11,
          callback: (value, index) => {
            return index % 2 ? '' : `$${value}`;
          },
          color: '#34363E',
          font: {
            size: 20,
            weight: 600,
            family: 'DM Sans',
          },
        },
        grid: {
          drawBorder: false,
          color: '#BDBDBD',
          borderDash: [2, 4],
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 40,
          color: '#34363E',
          font: {
            size: 20,
            weight: 600,
            family: 'DM Sans',
          },
        },
      },
    },
  },
};

const getConfigByOption = (dataset, isMobile) => {
  const cfg = { ...defaultConfig };
  cfg.data.datasets = commonDataset.map((cd, idx) => ({
    ...cd,
    data: dataset[idx],
  }));
  if (isMobile) {
    cfg.options.scales.x.ticks.padding = 8;
    cfg.options.scales.x.ticks.font.size = 14;
    cfg.options.scales.y.ticks.padding = 5;
    cfg.options.scales.y.ticks.font.size = 14;
  }
  return cfg;
};

const MonthlyCostGraph = () => {
  const isMobile = useMatchBreakpoint(768);
  const [selectedOption, setSelected] = useState(options[0]);

  const config = useMemo(() => {
    const dataset = datasets[selectedOption];
    return getConfigByOption(dataset, isMobile);
  }, [selectedOption, isMobile]);

  return (
    <>
      <Slider
        className={styles.slider}
        arrows={false}
        infinite={true}
        dots={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        swipeToSlide={true}
        nextArrow={false}
        prevArrow={false}
        beforeChange={(_, newIdx) => setSelected(Object.keys(datasets)[newIdx])}
      >
        {Object.keys(datasets).map((ds, idx) => (
          <div key={`chart_slider_${idx}`} className={styles.content}>
            <h2>{`${options[idx]} amount`}</h2>
            <div className={styles.chartContainer}>
              <Bar {...config} />
            </div>
            <div className={styles.footer}>
              <div className={styles.note}>
                Average term life insurance rates by age, non-smoker
              </div>
              <div className={styles.legend}>
                <div className={styles.female}>
                  <span className={styles.dot}></span> Female
                </div>
                <div className={styles.male}>
                  <span className={styles.dot}></span> Male
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className={styles.container}>
        <div className={styles.options}>
          {options.map((opt) => (
            <div
              key={opt}
              onClick={() => selectedOption !== opt && setSelected(opt)}
              className={classNames(
                styles.option,
                selectedOption === opt && styles.selected,
              )}
            >
              {opt}
            </div>
          ))}
        </div>
        <div className={styles.content}>
          <h2>{`${selectedOption} amount`}</h2>
          <div className={styles.chartContainer}>
            <Bar {...config} />
          </div>
          <div className={styles.footer}>
            <div className={styles.note}>
              Average term life insurance rates by age, non-smoker
            </div>
            <div className={styles.legend}>
              <div className={styles.female}>
                <span className={styles.dot}></span> Female
              </div>
              <div className={styles.male}>
                <span className={styles.dot}></span> Male
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyCostGraph;
